import map from 'lodash/map'
import filter from 'lodash/filter'
import pick from 'lodash/pick'
import moment from 'moment'

import api from 'services/api'

import {
  EXPIRING_CARD_LAST_NOTIFIED,
  CHINESE_LANGUAGE_CHECKOUT_LOCALES,
  USD_PRICING_PAGE_URL,
  USD_TEST_PRICING_PAGE_URL,
  CN_PRICING_PAGE_URL,
  DE_EUR_PRICING_PAGE_URL,
  ES_EUR_PRICING_PAGE_URL,
  FR_EUR_PRICING_PAGE_URL,
  IT_EUR_PRICING_PAGE_URL,
  INR_PRICING_PAGE_URL,
  JPY_PRICING_PAGE_URL,
  USD_CURRENCY_CODE,
  EUR_CURRENCY_CODE,
  INR_CURRENCY_CODE,
  JPY_CURRENCY_CODE
} from 'constants/account'
import {
  DE_LANGUAGE_CODE,
  ES_LANGUAGE_CODE,
  FR_LANGUAGE_CODE,
  IT_LANGUAGE_CODE
} from 'constants/languages'
import { MEMBERSHIP_STATUSES_LOOKUP } from 'constants/membership'
import { MARKETPLACES } from 'constants/countries'
import { MARKETPLACES as MARKETPLACES_ALT } from 'constants/market_insights/share_and_trends'
import { ZIPCODE_EXCLUSIONS } from '../constants/zipcodes'
import { hasErrors, hasEmptyFields } from './forms'
import { normalizedNameFormatter, toInteger } from './formatters'
import { SELLER_CENTRAL_ROOT_PATH } from 'constants/amazon_seller_accounts'

const addressValidationFields = [
  'address_line1',
  'address_city',
  'address_state',
  'address_zip'
]

export const getInvoiceIds = (invoices, id) => {
  if (id) return [id]

  return map(filter(invoices, { checked: true }), 'id')
}

export const hasBillingAddressErrors = (fields, errors) => {
  const fieldNames = ['firstName', 'lastName', ...addressValidationFields]
  if (ZIPCODE_EXCLUSIONS.includes(fields.address_country)) {
    const zipcodeExclusionFields = fieldNames.filter(
      field => field !== 'address_zip'
    )
    return (
      hasErrors(errors) || hasEmptyFields(pick(fields, zipcodeExclusionFields))
    )
  }

  return hasErrors(errors) || hasEmptyFields(pick(fields, fieldNames))
}

export const buildPlanDescription = ({
  normalized_name,
  charge_frequency,
  name,
  entity_type
}) => {
  if (charge_frequency >= 2 && charge_frequency <= 6) {
    return name
  }

  return entity_type === 'ExtensionType'
    ? `Extension ${normalized_name}`
    : normalizedNameFormatter(normalized_name)
}

export const shouldNotifyExpiringCard = (
  user,
  hasActiveMembership,
  cardInfo
) => {
  if (
    (hasActiveMembership && !user?.is_account_owner) ||
    !cardInfo?.exp_month ||
    !cardInfo?.exp_year
  )
    return false

  const today = moment(Date.now())
  const expirationDate = moment(
    new Date(cardInfo.exp_year, cardInfo.exp_month - 1)
  ).endOf('month')
  const lastNotified = window.localStorage.getItem(EXPIRING_CARD_LAST_NOTIFIED)

  return (
    today
      .clone()
      .endOf('month')
      .isSameOrAfter(expirationDate) &&
    (!lastNotified || !moment(lastNotified).isSame(today, 'day'))
  )
}

const getEuroPricingPageUrl = languageCode => {
  switch (languageCode) {
    case ES_LANGUAGE_CODE:
      return ES_EUR_PRICING_PAGE_URL
    case FR_LANGUAGE_CODE:
      return FR_EUR_PRICING_PAGE_URL
    case IT_LANGUAGE_CODE:
      return IT_EUR_PRICING_PAGE_URL
    case DE_LANGUAGE_CODE:
    default:
      return DE_EUR_PRICING_PAGE_URL
  }
}

export const getPricingPageUrl = (
  checkoutLocale,
  currency,
  languageCode,
  group,
  hasPlan = true
) => {
  if (
    CHINESE_LANGUAGE_CHECKOUT_LOCALES.includes(checkoutLocale?.toUpperCase())
  ) {
    return CN_PRICING_PAGE_URL
  }

  let url = ''

  switch (currency?.toLowerCase()) {
    case EUR_CURRENCY_CODE:
      url = getEuroPricingPageUrl(languageCode)
      break
    case INR_CURRENCY_CODE:
      url = INR_PRICING_PAGE_URL
      break
    case JPY_CURRENCY_CODE:
      url = JPY_PRICING_PAGE_URL
      break
    case USD_CURRENCY_CODE:
    default:
      url = hasPlan ? USD_PRICING_PAGE_URL : USD_TEST_PRICING_PAGE_URL
  }

  return group ? `${url}?plan=${group.toLowerCase()}` : url
}

// Todo: remove these unused arguments
export const getCustomerPortalLink = async (
  productId = null,
  priceId = null
) => {
  const response = await api.stripeUpgradeLink({ productId, priceId })
  if (response.ok) {
    return response.data.redirect_url
  }

  return false
}

export const getBillingInfoLink = async () => {
  const response = await api.billingInfoLink()
  if (response.ok) {
    return response.data.redirect_url
  }
  return false
}

export const getMembershipStatus = statusId => {
  if (!statusId) {
    return null
  }

  return MEMBERSHIP_STATUSES_LOOKUP[statusId]
}

export const getTrialEndDate = (startDate, trialDays) => {
  if (!(startDate && trialDays)) {
    return null
  }

  // clone startDate because we don't want to mutate startDate object
  const expirationDate = new Date(startDate.getTime())

  // adds days to current time
  expirationDate.setDate(expirationDate.getDate() + trialDays)

  return expirationDate
}

export const getPlanFrequency = (billingFrequency = '', chargeFrequency = 0) =>
  billingFrequency.toLowerCase() === 'yearly'
    ? 12 * chargeFrequency
    : chargeFrequency

export const buildSegmentSubscriptionData = ({ membershipInfo, user }) => {
  const {
    id,
    base_plan_id,
    billing_frequency,
    charge_frequency,
    membership_created_at,
    trial_period_days,
    users,
    stripe_plan,
    unique_name,
    code,
    membershipStatus,
    price,
    plan_user_limit,
    subscription_id
  } = membershipInfo

  const planStartDate = membership_created_at
    ? new Date(membership_created_at)
    : null

  const trialStartDate =
    planStartDate && trial_period_days ? planStartDate : null

  return {
    currency: stripe_plan?.currency || 'usd',
    planFrequency: getPlanFrequency(billing_frequency, charge_frequency),
    planName: unique_name || 'No Membership',
    planCode: code || 0,
    planBillingAmount: price ? toInteger(price) : 0,
    planStatus:
      getMembershipStatus(membershipStatus) || 'Cancelled At Period End',
    paidSeats: plan_user_limit || 0,
    usedSeats:
      users && Array.isArray(users)
        ? users.filter(_user => _user.confirmed).length + 1
        : 1,
    planTypeID: base_plan_id || id,
    product: 'Jungle Scout',
    planStartDate: planStartDate?.toISOString(),
    subscriptionId: subscription_id?.toString(),
    trialStartDate: trialStartDate?.toISOString(),
    trialExpirationDate: getTrialEndDate(
      trialStartDate,
      trial_period_days
    )?.toISOString(),
    extension: user.has_extension_access
  }
}

export const getDefaultMarketplaceForSellerId = (accounts, sellerId) => {
  const account = accounts?.find(acc => acc.merchant_id === sellerId)

  if (!account) {
    return null
  }

  const country = account.countries?.find(item => item.is_default)

  if (!country) {
    return null
  }

  return MARKETPLACES?.[country?.country_code?.toLowerCase()]
}

/**
 * Get membershipInfo from globalData and check if group is equal to 'Free'
 *
 * @param {*} membershipInfo Membership information (globalData.membershipInfo)
 *
 * @returns True in case user has a Freemium membership
 */
export const isFreemiumPlan = membershipInfo => {
  const membershipGroup = membershipInfo?.group
  return membershipGroup === 'Free'
}

export const shouldShowPpcPermissionsBanner = selectedAccount => {
  if (!selectedAccount) {
    return false
  }

  const {
    aasm_state,
    created_at,
    last_successful_exports_request,
    ppc_credential_aasm_state,
    ppc_credential_created_at
  } = selectedAccount

  const accountCreatedAt = new Date(created_at)
  const ppcCredentialCreatedAt = ppc_credential_created_at
    ? new Date(ppc_credential_created_at)
    : null
  const lastSuccessfulExportsRequest = last_successful_exports_request
    ? new Date(last_successful_exports_request)
    : null

  // false if account or ppcCredential is younger than 72 hours
  const _72_HOURS = 72 * 60 * 60 * 1000
  const threeDaysAgo = new Date() - _72_HOURS

  if (
    !ppcCredentialCreatedAt ||
    accountCreatedAt > threeDaysAgo ||
    ppcCredentialCreatedAt > threeDaysAgo
  ) {
    return false
  }

  if (aasm_state !== 'enabled' || ppc_credential_aasm_state !== 'healthy') {
    return false
  }

  if (lastSuccessfulExportsRequest > threeDaysAgo) {
    return false
  }

  return true
}

/**
 * Get the Seller Central hostname for the given region and country
 *
 * @param {string} region
 * @param {string} country
 * @returns {string} Seller Central hostname
 */
export const getSellerCentralHostname = (region, country) => {
  const regionLowercase = region.toLowerCase()

  if (regionLowercase === 'fe') {
    const marketplaceId = MARKETPLACES_ALT[country.toLowerCase()]?.id

    return SELLER_CENTRAL_ROOT_PATH[regionLowercase][marketplaceId]
  }

  return SELLER_CENTRAL_ROOT_PATH[regionLowercase]
}
